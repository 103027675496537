.block-27 {
	ul {
		padding: 0;
		margin: 0;
		li {
			display: inline-block;
			margin-bottom: 4px;
			font-weight: 400;
			a,span {
				color: $primary;
				text-align: center;
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				border-radius: 50%;
				border: 1px solid lighten($black,90%);
			}
			&.active {
				a, span {
					background: $primary;
					color: $white;
					border: 1px solid transparent;
				}
			}
		}
	}
}

.contact-section {
	.contact-info{
		p{
			a{
			}
			span{
				color: $black;
			}
		}
	}
}


.contact-form{
	.form-group{
		position: relative;
	}
	.form-control {
		border: transparent !important;
		border-bottom: 1px solid rgba(0,0,0,.08) !important;
		height: 58px!important;
		padding-left: 0;
		padding-right: 0; 
		background: transparent!important;
		color: rgba(0,0,0,.9) !important;
		font-size: 13px;
		border-radius: 0px;
		box-shadow: none!important;
		&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		  color: rgba(0,0,0,.9);
		}
		&::-moz-placeholder { /* Firefox 19+ */
		  color: rgba(0,0,0,.9);
		}
		&:-ms-input-placeholder { /* IE 10+ */
		  color: rgba(0,0,0,.9);
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: rgba(0,0,0,.9);
		}
		&:focus, &:active {
			border-color: $primary !important;
		}
	}
	textarea.form-control {
		height: inherit!important;
	}
}


.block-9 {

	.form-control {
		outline: none!important;
		box-shadow: none!important;
		font-size: 15px;
	}
	#map {
	}
}