.testimony-section{
	position: relative;
	.owl-carousel{
		margin: 0;
	}
	.owl-carousel .owl-stage-outer{
		padding-bottom: 2em;
		position: relative;
	}
	.owl-nav {
		position: absolute;
		top: 100%;
		width: 100%;
		.owl-prev,
		.owl-next {
			position: absolute;
			transform: translateY(-50%);
			margin-top: -10px;
			outline: none !important;
			@include transition(.3s);
			span {
				&:before {
					font-size: 30px;
					color: rgba(0,0,0,.2);
					@include transition(.3s);
				}
			}
			&:hover,&:focus{
				span{
					&:before{
						color: $black;
					}
				}
			}
			opacity: 0;
		}
		.owl-prev {
			left: 50%;
			margin-left: -80px;
		}
		.owl-next {
			right: 50%;
			margin-right: -80px;
		}
	}
	&:hover{
		.owl-nav{
			.owl-prev,
			.owl-next{
				opacity: 1;
			}
			.owl-prev {
				left: 50%;
				margin-left: -80px;
			}
			.owl-next {
				right: 50%;
				margin-right: -80px;
			}
		}
	}
	.owl-dots {
		text-align: center;
		.owl-dot {
			width: 10px;
			height: 10px;
			margin: 5px;
			border-radius: 50%;
			background: rgba(0,0,0,.2);
			&.active {
				background: $primary;
				@include gradient-background();
			}
		}
	}
}
.testimony-wrap{
	display: block;
	position: relative;
	background: rgba(255,255,255,.1);
	color: rgba(0,0,0,.8);
	.user-img{
		width: 110px;
		height: 110px;
		border-radius: (50%);
		position: relative;
	}
	.name{
		font-weight: 500;
		font-size: 18px;
		margin-bottom: 0;
		color: $black;
	}
	.position{
		font-size: 13px;
	}
	.line{
		position: relative;
		border-left: 1px solid lighten($black,90%);
	}
	.quote{
		position: absolute;
		top: 50%;
		left: -20px;
		width: 40px;
		height: 40px;
		transform: translateY(-50%);
		background: $primary;
		@include border-radius(50%);
		i{
			color: $white;
		}
	}
}

.ftco-social{
	a{
		width: 30px;
		height: 30px;
		display: inline-block;
		margin-right: 2px;
		background: lighten($primary,40%);
		@include border-radius(50%);
		span{
			color: $primary;
		}
	}
}