.ftco-navbar-light {
	background: transparent !important;
	position: absolute;
	top: 10px;
	left: 0;
	right: 0;
	z-index: 3;
	padding: 0;
	@include media-breakpoint-down(md) {
		background: $black!important;
		position: relative;
		top: 0;
		padding: 10px 15px;
	}

	.navbar-brand {
		color: $black;
		line-height: 1;
		span{
			//color: $primary;
			line-height: 1;
		}
		&:hover, &:focus{
			color: $black;
		}

		@include media-breakpoint-down(md){
			color: $white;
			.icon2-cheval {
				color: $white;

			}
			
		}
	}

	.navbar-nav {
		@include media-breakpoint-down(md){
			padding-bottom: 10px;
		}
		> .nav-item {
			> .nav-link {
				font-size: 14px;
				padding-top: 1.5rem;
				padding-bottom: 1.5rem;
				padding-left: 20px;
				padding-right: 20px;
				font-weight: 300;
				color: $black;
				&:hover {
					color: $black;
				}
				opacity: 1!important;
				@include media-breakpoint-down(md){
					padding-left: 0;
					padding-right: 0;
					padding-top: .9rem;
					padding-bottom: .9rem;
					color: rgba(255,255,255,.7);
					&:hover{
						color: $white;
					}
				}
			}

			.dropdown-menu{
				border: none;
				background: $white;
				-webkit-box-shadow: 0px 10px 34px -20px rgba(0,0,0,0.41);
				-moz-box-shadow: 0px 10px 34px -20px rgba(0,0,0,0.41);
				box-shadow: 0px 10px 34px -20px rgba(0,0,0,0.41);
				.dropdown-item{
					font-size: 14px;
					&:hover, &:focus{
						background: transparent;
						color: $black;
					}
				}
			}

			
			&.ftco-seperator {
				position: relative;
				margin-left: 20px;
				padding-left: 20px;
				@include media-breakpoint-down(md) {
					padding-left: 0;
					margin-left: 0;
				}
				&:before {
					position: absolute;
					content: "";
					top: 10px;
					bottom: 10px;
					left: 0;
					width: 2px;
					background: rgba($white, .05);
					@include media-breakpoint-down(md) {
						display: none;
					}
				}
			}
			&.cta {
				> a {
					color: $black;
					@include media-breakpoint-down(sm){
						padding-left: 15px;
						padding-right: 15px;
					}
					@include media-breakpoint-down(md){
						color: $white;
						background: $primary;
					}
				}
			}
			&.active {
				> a {
					color: $accent;
					@include media-breakpoint-up(md){
						color: $primary;
					}
					&:after{
						opacity: 1;
						background: $primary;
					}
				}
			}
		}
	}
	
	.navbar-toggler {
		border: none;
		color: rgba(255,255,255,.5)!important;
		cursor: pointer;
		padding-right: 0;
		text-transform: uppercase;
		font-size: 16px;
		letter-spacing: .1em;
		&:focus{
			outline: none !important;
		}
	}
	
	&.scrolled  {
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		margin-top: -130px;
		background: $white!important;
		box-shadow: 0 0 10px 0 rgba(0,0,0,.1);
		.icon2-cheval{
			color: $primary;
			fill: $primary;
		}
		.nav-item {
			&.active {
				> a {
					color: $primary!important;
				}
			}
			&.cta {
				> a {
					color: $white !important;
					background: $primary;
					border: none !important;
					 
					span {
						display: inline-block;
						color: $white !important;
					}
				}
				&.cta-colored {
					span {
						border-color: $primary;
					}
				}
			}
		}

		.navbar-nav {
			@include media-breakpoint-down(md) {
				background: none;
				border-radius: 0px;
				padding-left: 0rem!important;
				padding-right: 0rem!important;
			}
		}

		.navbar-nav {
			@include media-breakpoint-down(sm) {
				background: none;
				padding-left: 0!important;
				padding-right: 0!important;
			}
		}

		.navbar-toggler {
			border: none;
			color: rgba(0,0,0,.5)!important;
			border-color: rgba(0,0,0,.5)!important;
			cursor: pointer;
			padding-right: 0;
			text-transform: uppercase;
			font-size: 16px;
			letter-spacing: .1em;

		}
		.nav-link {
			padding-top: .9rem!important;
			padding-bottom: .9rem!important;
			color: $black!important;
			&.active {
				color: $primary!important;
			}
		}
		&.awake {
			margin-top: 0px;
			transition: .3s all ease-out;
		}
		&.sleep {
			transition: .3s all ease-out;	
		}

		.navbar-brand {
			color: $black;
		}
	}
}

.navbar-brand {
	font-weight: 300;
	font-size: 22px;
	// text-transform: uppercase;
	letter-spacing: 2px;
}