#section-counter{
	position: relative;
	z-index: 0;
	.overlay{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		// background: $black;
		@include gradient-background();
		opacity: .8;
	}
}

.ftco-counter {
	padding: 7em 0;
	@include media-breakpoint-down(lg){
		background-position: center center !important;
	}
	.icon{
		width: 80px;
		height: 80px;
		margin: 0 auto;
		position: relative;
		margin-bottom: 20px;
		z-index: 0;
		&:after{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			content: '';
			bordeR: 1px solid $primary;
			z-index: -1;
			@include transition(.3s);
		}
		span{
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			color: $primary;
		}
	}
	.text{
		strong.number{
			font-weight: 500;
			font-size: 50px;
			color: $white;
		}
		span{
			font-weight: 400;
			font-size: 14px;
			color: rgba(255,255,255,.7);
		}
		&:hover, &:focus{
			.icon{
				span{
					color: $black;
				}
				&:after{
					transform: rotate(135deg);
					background: $primary;
				}
			}
		}
	}
	.counter-wrap{
		@include media-breakpoint-down(md){
			margin-bottom: 40px !important;
		}
	}
	.ftco-number {
		display: block;
		color: $white;
	}
	.ftco-label {
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: .1em;
	}
}