#map {
	width: 100%;
	&.map{
		height: 400px;
	}
	@include media-breakpoint-down(md) {
		height: 300px;
	}
}


@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($primary, 0.2);
  }
  70% {
      -webkit-box-shadow: 0 0 0 30px rgba($primary, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($primary, 0.2);
    box-shadow: 0 0 0 0 rgba($primary, 0.2);
  }
  70% {
      -moz-box-shadow: 0 0 0 30px rgba($primary, 0);
      box-shadow: 0 0 0 30px rgba($primary, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba($primary, 0);
      box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}

.heading-section{
	z-index: 0;
	.subheading{
		font-size: 16px;
		display: block;
		margin-bottom: 5px;
		color: lighten($black,70%);
		line-height: 1;
		z-index: 1;
		font-weight: 300;
		text-transform: uppercase;
		letter-spacing: 3px;
	}
	h2{
		z-index: -1;
		font-size: 60px;
		font-weight: 300;
		position: relative;
		@include media-breakpoint-down(sm){
			font-size: 28px;
		}
	}
}