@import 'mixins/all';
@import 'variables';
@import 'bootstrap/bootstrap';
@import 'bootstrap/_variables';
@import 'layout/navigation';
@import 'elements/carousel';
@import 'elements/button';
@import 'elements/intro';
@import 'elements/gallery';
@import 'elements/services';
@import 'elements/coach';
@import 'elements/schedule';
@import 'elements/testimonial';
@import 'elements/misc';
@import 'elements/map';
@import 'elements/background';
@import 'elements/popup';
@import 'elements/counter';
@import 'elements/block';
@import 'elements/pricing';
@import 'elements/pagination';
@import 'elements/blog';
@import 'elements/search';
@import 'elements/sidebar';

body {
	font-family: $font-primary;
	font-size: 16px;
	line-height: 1.8;
	font-weight: 400;
	color: lighten($black,70%);
	&.menu-show {
		overflow: hidden;
		position: fixed;
		height: 100%;
		width: 100%;
	}
}
span{
	color: lighten($black,75%);
}

//USEFULL CODES

.bg-light {
	background: lighten($black,98%)!important;
}
.bg-white {
	background: $white !important;
}

.ftco-no-pt{
	padding-top: 0 !important;
}
.ftco-no-pb{
	padding-bottom: 0 !important;
}

.bg-primary{
	background: $primary;
}