.ftco-gallery{
	padding: 0;
}
.gallery{
	display: block;
	height: 270px;
	position: relative;
	.icon{
		width: 60px;
		height: 60px;
		margin: 0 auto;
		z-index: 0;
		opacity: 0;
		position: relative;
		@include transition(.6s);
		background: $primary;
		@include border-radius(50%);
		span{
			color: $white;
		}
	}
	&:hover, &:focus{
		.icon{
			opacity: 1;
		}
	}	
}
