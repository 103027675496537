.services{
	width: 100%;
	display: block;
	margin-bottom: 40px;
	.icon{
		width: 100px;
		height: 100px;
		background: lighten($primary,25%);
		@include border-radius(50%);
		span{
			font-size: 44px;
			color: $primary;
		}
	}
	.text{
		width: 100%;
		h3{
			font-size: 20px;
			font-weight: 500;
		}
	}
}

.package-program{
	margin-bottom: 0;
	position: relative;
	z-index: 0;
	overflow: hidden;
	&:after{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $primary;
		z-index: 0;
		content: '';
		opacity: .5;
		@include transition(.3s);
	}
	.img{
		display: block;
		height: 400px;
	}
	.text{
		z-index: 1;
		opacity: 1;
		@include transition(.2s);
		h3{
			margin-bottom: 10px;
			font-size: 30px;
			font-weight: 500;
			color: $white;
		}
		color: rgba(255,255,255,.8);

	}
	&:hover, &:focus{
		&:after{
			opacity: .5;
		}
		.text{
			opacity: 1;
		}
	}
}

.btn-custom{
	@include gradient-background();
	color: $white;
	display: block;
	font-size: 18px;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 600;
	@include border-radius(2px);
	span{
		color: $white;
	}
	&:hover, &:focus{
		color: $white;
	}
}