.sidebar-box {
	margin-bottom: 30px;
	padding: 25px;
	font-size: 15px;
	width: 100%;
	
	float: left;
	
	background: $white;
	*:last-child {
		margin-bottom: 0;
	}
	h3.heading-2 {
		font-size: 18px;
		margin-bottom: 30px;
	}
}

.categories, .sidelink {
	li {
		position: relative;
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid gray('300');
		list-style: none;
		&:last-child {
			margin-bottom: 0;
			border-bottom: none;
			padding-bottom: 0;
		}
		a {
			display: block;
			color: $black;
			span {
				position: absolute;
				right: 0;
				top: 0;
				color: #ccc;
			}
		}
		&.active {
			a {
				color: $black;
				font-style: italic;
			}
		}
	}
}


.search-form {
	background: lighten($black, 95%);
	padding: 10px;

	.form-group {
		position: relative;
		background: $white;
		input {
			padding-right: 50px;
			padding-left: 15px;
		}
	}
	.icon {
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);
	}
}

#ftco-loader {
	position: fixed;
	width:  96px;
	height: 96px;
	left:  50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(255,255,255,0.9);
	box-shadow: 0px 24px 64px rgba(0,0,0,0.24);
	border-radius:16px;
	opacity: 0; 
	visibility: hidden;
	transition: opacity .2s ease-out, visibility 0s linear .2s;
	z-index:1000;
}

#ftco-loader.fullscreen {
	padding:  0;
	left:  0;
	top:  0;
	width:  100%;
	height: 100%;
	transform: none;
	background-color: #fff;
	border-radius: 0;
	box-shadow: none;
}

#ftco-loader.show {
	transition: opacity .4s ease-out, visibility 0s linear 0s;
	visibility: visible;
	opacity: 1;
}

#ftco-loader .circular {
  animation: loader-rotate 2s linear infinite;
  position: absolute;
  left:  calc(50% - 24px);
  top:  calc(50% - 24px);
  display: block;
  transform: rotate(0deg);
}

#ftco-loader .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: loader-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
}