
.sched-list{
	@include media-breakpoint-down(xl){
		overflow-x: scroll;
	}
}
.table{
	min-width: 1000px !important;
	width: 100%;
	text-align: center;
	th{
		font-weight: 500;
	}
	.thead-primary{
		background: $primary;
		tr{
			th{
				padding: 20px 10px;
				color: $white !important;
				border: 1px solid transparent !important;
			}
		}
	}
	tbody{
		tr{
			td{
				text-align: center !important;
				vertical-align: middle;
				padding: 40px 10px;
				border: 1px solid transparent !important;
				// border-bottom: 1px solid rgba(255,255,255,.1) !important;
				h3{
					margin-bottom: 20px;
					font-size: 16px;
					a{
						text-transform: uppercase;
						color: rgba(255,255,255,1);
					}
				}
				.at{
					margin-bottom: 15px;
					display: block;
					color: rgba(255,255,255,.8);
				}
				.time{
					color: rgba(255,255,255,.8);
				}
				.trainer{
					color: $white;
				}
				&.color{
					background: $primary;
				}
				&.color-1{
					background: #7acfdf;
				}
				&.color-2{
					background: #26baee;
				}
				&.color-3{
					background: #fb929e;
				}
				&.color-4{
					background: #12e6c8;
				}
				&.color-5{
					background: #a287f4;
				}
			}
		}
	}
}