.sidebar-wrap{
	padding: 20px;
	border: 1px solid lighten($black,90%);
	margin-bottom: 30px;
	.heading{
		font-size: 18px;
	}
	.fields {
		width: 100%;
		position: relative;
		.form-control {
			box-shadow: none!important;
			border: transparent;
			background: $white !important;
			color: lighten($black,30%) !important;
			border: 1px solid lighten($black,90%);
			font-size: 14px;
			width: 100%;
			height: 52px !important;
			padding: 10px 20px;
			@include border-radius(0);
			&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: lighten($black,30%);
			}
			&::-moz-placeholder { /* Firefox 19+ */
			  color: lighten($black,30%);
			}
			&:-ms-input-placeholder { /* IE 10+ */
			  color: lighten($black,30%);
			}
			&:-moz-placeholder { /* Firefox 18- */
			  color: lighten($black,30%);
			}
		}
		.icon {
			position: absolute;
			top: 50%;
			right: 30px;
			font-size: 14px;
			transform: translateY(-50%);
			color: rgba($black,.7);
			@include media-breakpoint-down(sm) {
				right: 10px;
			}
		}
		.textfield-search, .select-wrap {
		}
		.textfield-search {
			input {

			}
		}
		.select-wrap {
			position: relative;
			select {
				appearance: none;
			}
		}
	}
	.form-group{
		.btn{
			width: 100%;
			display: block !important;
			@include border-radius(2px);
		}
	}
}