.post-info {
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
	color: $white;
	letter-spacing: .1em;
	> div {
		display: inline-block;

		.seperator {
			display: inline-block;
			margin: 0 10px;
			opacity: .5;
		}
	}
}

.tagcloud {
	a {
		text-transform: uppercase;
		display: inline-block;
		padding: 4px 10px;
		margin-bottom: 7px;
		margin-right: 4px;
		border-radius: 4px;
		color: $black;
		border: 1px solid lighten($black,90%);
		font-size :11px;
		&:hover {
			border: 1px solid #000;
		}
	}
}

.comment-form-wrap {
	clear: both;
}

.comment-list {
	padding: 0;
	margin: 0;
	.children {
		padding: 50px 0 0 40px;
		margin: 0;
		float: left;
		width: 100%;
	}
	li {
		padding: 0;
		margin: 0 0 30px 0;
		float: left;
		width: 100%;
		clear: both;
		list-style: none;
		.vcard {
			width: 80px;
			float: left;
			img {
				width: 50px;
				border-radius: 50%;
			}
		}
		.comment-body {
			float: right;
			width: calc(100% - 80px);
			h3 {
				font-size: 20px;
			}
			.meta {
				text-transform: uppercase;
				font-size: 13px;
				letter-spacing: .1em;
				color: #ccc;
			}
			.reply {
				padding: 5px 10px;
				background: lighten($black,90%);
				color: $black;
				text-transform: uppercase;
				font-size: 11px;
				letter-spacing: .1em;
				font-weight: 400;
				border-radius: 4px;
				&:hover {
					color: $white;
					background: lighten($black, 0%);
				}
			}
		}
	}
}