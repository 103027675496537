.block-7 {
	margin-bottom: 30px;
	padding: 30px;
	background: $white;
	-webkit-box-shadow: 0px 24px 48px -13px rgba(0,0,0,0.05);
	-moz-box-shadow: 0px 24px 48px -13px rgba(0,0,0,0.05);
	box-shadow: 0px 24px 48px -13px rgba(0,0,0,0.05);
	@include transition(.3s);
	.heading {
		font-size: 14px;
		line-height: 1;
		margin: 0;
		padding: 0;
		font-weight: 500;
		margin-bottom: 10px;
		display: inline-block;
		text-transform: uppercase;

	}
	.heading-2 {
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: 600;
	}
	.price {
		margin: 0;
		padding: 0;
		display: block;
		sup {
			font-size: 24px;
			top: -1em; 
			color: $primary;
		}
		.number {
			font-size: 60px;
			font-weight: 500;
			color: $primary;
		}
	}
	.excerpt {
		margin-bottom: 20px;
		color: lighten($black,60%);
	}
	.label2 {
		text-transform: uppercase;
	}
	.pricing-text {
		&, li, ul {
			padding: 0;
			margin: 0;
		}
		li {
			list-style: none;
			margin-bottom: 15px;
			color: lighten($black,30%);
		}
	}
	.btn-primary{
		color: $white;
		border: transparent;
		text-transform: uppercase;
		font-style: 16px;
		font-weight: 600;
		letter-spacing: 1px;
		position: absolute;
		// bottom: 0;
		left: 0;
		right: 0;
		width: 60%;
		margin: 0 auto;
		&:hover, &:focus{
			background: $primary !important; 
			color: $white;
		}
	}
	&:hover, &:focus{
		-webkit-box-shadow: 0px 24px 48px -13px rgba(0,0,0,0.11);
		-moz-box-shadow: 0px 24px 48px -13px rgba(0,0,0,0.11);
		box-shadow: 0px 24px 48px -13px rgba(0,0,0,0.11);
	}

}
