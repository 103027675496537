.block-20 {
	overflow: hidden;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	position: relative;
	display: block;
	width: 100%;
	height: 270px;
}
.blog-entry{
	overflow: hidden;
	@include media-breakpoint-up(md){
		margin-bottom: 30px;
	}
	@include media-breakpoint-down(sm){
		margin-bottom: 30px;
	}
	.text {
		position: relative;
		margin-top: -40px;
		background: $white;
		width: 100%;
		.heading {
			font-size: 18px;
			margin-bottom: 16px;
			font-weight: 400;
			a {
				color: $black;
				&:hover, &:focus, &:active {
					color: $primary;
				}
			}
		}
	}
	.meta {
		> div {
			display: inline-block;
			margin-right: 5px;
			margin-bottom: 0;
			font-size: 14px;
			a {
				color: lighten($black, 50%);
				font-size: 15px;
				&:hover {
					color: lighten($black, 40%);
				}
			}
		}
	}
	.one{
		width: 80px;
	}
	.two{
		width: calc(100% - 80px);
	}
	span.day{
		font-size: 58px;
		font-weight: 300;
		color: $primary;
		line-height: 1;
	}
	span.yr,span.mos{
		display: block;
		color: lighten($black,70%);
	}
}


.block-23 {
	ul {
		padding: 0;
		li {
			
			&, > a {
				display: table;
				line-height: 1.5;
				margin-bottom: 15px;
			}
			span{
				color: rgba($white, .7);
			}
			.icon, .text {
				display: table-cell;
				vertical-align: top;
			}
			.icon {
				width: 40px;
				font-size: 18px;
				padding-top: 2px;
				color: rgba($white, 1);
			}
			
		}
	}
}

.block-6 {
	.icon {
		span {
			&:before {
			}
		}
	}
	.media-body {
		.heading {

		}
		p {
		}
	}
} 

.block-18 {
	.icon, .text {
	}
	.icon {
		> span {
			font-size: 40px;
		}
	}
	.text {
		strong {
			font-size: 30px;
		}
		span {
			display: block;
		}
	}
}

.block-21 {
	.blog-img{
		display: block;
		height: 80px;
		width: 80px;
	}
	.text {
		width: calc(100% - 100px);
		.heading {
			font-size: 16px;
			a {
				color: $black;
				&:hover, &:active, &:focus {
					color: $primary;
				}
			}
		}
		.meta {
			> div {
				display: inline-block;
				font-size: 12px;
				margin-right: 5px;
				a {
					color: lighten($black, 50%);
				}
			}
		}
	}
}

.custom-pagination {
	width: 100%;
	text-align: center;
	display: inline-block;
	li {
		display: inline-block;
	}
	.prev, .next {
		a {
			font-size: 20px!important;
			line-height: 38px!important;
		}
	}
	li, .prev, .next {
		a {
			width: 40px;
			height: 40px;
			line-height: 40px;
			padding: 0;
			margin: 0;
			border-radius: 50%!important;
			font-size: 16px;
		}
		&.active {
			a {
				display: block;
				width: 40px;
				height: 40px;
				line-height: 40px;
				padding: 0;
				margin: 0;
				border-radius: 50%!important;
				font-size: 16px;
				background: $primary;
				color: $white;
				&:hover, &:focus {
					color: $white;
				}
			}	
		}
	}
	.prev {
		float: left;
	}
	.next {
		float: right;
	}
}