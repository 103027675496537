@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
  }
  
  @mixin transition($transition) {
      -moz-transition:    all $transition ease;
      -o-transition:      all $transition ease;
      -webkit-transition: all $transition ease;
      -ms-transition: 		all $transition ease;
      transition:         all $transition ease;
  }
  
  @mixin gradient-background(){
      background: $gradient-start;
      background: -moz-linear-gradient(45deg, $gradient-start 0%, rgba(255,255,255,0) 100%);
      background: -webkit-gradient(left bottom, right top, color-stop(0%, $gradient-start), color-stop(100%, rgba(255,255,255,0)));
      background: -webkit-linear-gradient(45deg, $gradient-start 0%, rgba(255,255,255,0) 100%);
      background: -o-linear-gradient(45deg, $gradient-start 0%, rgba(255,255,255,0) 100%);
      background: -ms-linear-gradient(45deg, $gradient-start 0%, rgba(255,255,255,0) 100%);
      background: linear-gradient(45deg, $gradient-start 0%, rgba(255,255,255,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d291bc', endColorstr='#ffffff', GradientType=1 );
  }

  @keyframes loader-rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes loader-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -136px;
    }
  }