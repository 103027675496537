.coach{
	width: 100%;
	display: block;
	margin-bottom: 30px;
	.img,.text{
		width: 100%;
	}
	.img{
		height: 400px;
		background-position: top center !important;
	}
	.text{
		.subheading{
			font-size: 13px;
			color: rgba(0,0,0,.4);
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-weight: 500;
		}
		h3{
			font-size: 22px;
			color: $black;
			a{
				color: $black;
			}
		}
		.ftco-social-media{
			padding: 0;
			li{
				list-style: none;
				a{
					display: block;
					width: 40px;
					height: 40px;
					background: $primary;
					@include border-radius(50%);
					span{
						font-size: 24px;
						color: $white;
					}
				}
			}
		}
	}
}
