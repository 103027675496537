.owl-carousel {
	position: relative;
	.owl-item {
		opacity: .4;
		&.active {
			opacity: 1;
		}
	}
	
	.owl-nav {
		position: absolute;
		top: 50%;
		width: 100%;
		.owl-prev,
		.owl-next {
			position: absolute;
			transform: translateY(-50%);
			margin-top: -10px;
			color: $primary !important;
			@include transition(.7s);
			span {
				&:before {
					font-size: 30px;
				}
			}
			opacity: 0;
		}
		.owl-prev {
			left: 0;
		}
		.owl-next {
			right: 0;
		}
	}
	&:hover{
		.owl-nav{
			.owl-prev,
			.owl-next{
				opacity: 1;
			}
			.owl-prev {
				left: -25px;
			}
			.owl-next {
				right: -25px;
			}
		}
	}
}


.hero-wrap{
	width: 100%;
	position: relative;
	.overlay{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: '';
		opacity: .3;
		@include gradient-background();
		@include media-breakpoint-down(md){
			width: 100%;
		}
	}
	.slider-text{
		color: $white;
		.breadcrumbs{
			text-transform: uppercase;
			font-size: 14px;
			letter-spacing: 1px;
			margin-bottom: 20px;
			z-index: 99;
			font-weight: 400;
			letter-spacing: 4px;
			span{
				color: rgba(0,0,0,.7);
				a{
					color: $black;
				}
			}
		}
		h1{
			font-size: 80px;
			color: $black;
			font-weight: 200;
			span{
				color: $black;
			}
		}
		h2{
			font-weight: 200;
		}
		.typewrite > .wrap {
			position: relative;
		  border-right: 1px solid rgba(0,0,0,.1);
		}

		.bread{
			font-size: 70px;
			color: $black;
			line-height: 1.2;
			font-weight: 200;
			@include media-breakpoint-down(md) {
				font-size: 60px;
			}
		}
		.btn-primary{
			border: 1px solid rgba(255,255,255,.4);
			@include border-radius(30px);
			&:hover, &:focus{
				background: $white !important;
				color: $black;
			}
		}
	}

}

@keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}