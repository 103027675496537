.hero-wrap,
.img,
.blog-img,
.user-img{
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}


.img-about{
	@include media-breakpoint-down(sm){
		height: 400px;
		margin-bottom: 30px;
	}
}